.nav-bg {
	background-color: black;
}

.white-text {
	color: white !important;
}

.white-btn {
	@extend .white-text;
	&:hover {
		color: gray !important;
	}
}

.bg-img {
	width: 100%;
	height: 100%;
}