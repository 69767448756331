.home-container {
	position: relative;
	text-align: center;
	color: white;
	overflow: hidden;
}

.home-info {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -120%);
	text-align: center;
}

.home-socials {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	list-style: none;

	li {
		display:inline;
		padding: 0 15px;
		a {
			font-size: 1.5rem;
		}
		// border-left: solid 1px white;
		&:first-child {
			border-left: none;
		}
	}
}

.socials-link {
	transition: ease 0.5s;
	color: white;
	text-decoration: none;

	&:hover {
		text-decoration: none;
		transition: ease 0.5s;
	}
}

.socials-spotify {
	@extend .socials-link;
	&:hover {
		color: #1DB954;
	}
}

.socials-yt {
	@extend .socials-link;
	&:hover {
		color: #e52d27;
	}
}

.socials-am {
	@extend .socials-link;
	&:hover {
		color: #A3AAAE;
	}
}

.socials-discord {
	@extend .socials-link;
	&:hover {
		color: #7289DA;
	}
}

.socials-telegram {
	@extend .socials-link;
	&:hover {
		color: #0088cc;
	}
}

.socials-instagram {
	@extend .socials-link;
	&:hover {
		color: #F56040;
	}
}

.socials-twitter {
	@extend .socials-link;
	&:hover {
		color: #1DA1F2;
	}
}

.socials-github {
	@extend .socials-link;
	&:hover {
		color: #24292e;
	}
}

.socials-hackerrank {
	@extend .socials-link;
	&:hover {
		color: #2c3e50;
	}
}

.home-bg-image {
	width: 100vw;
	height: 100vh;
	// background-image: url("/img/bg.png");
	background-color: #131516;
	background-size: cover;
	overflow: hidden;
}